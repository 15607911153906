<template>
    <ion-page >
        <nav-bar background-color="white" :show-back-button="false"></nav-bar>
        <ion-content :fullscreen="true" >
            <div class="container flex flex-col h-full">
                <bubble-text persona="both">Bienvenue sur Happy Madeleine ! <br>Cette 1ère version est utilisable uniquement sur ordinateur ou tablette en format paysage. L'utilisation sur téléphone n'est pas recommandée.</bubble-text>

                <div class="pt-8 flex flex-col items-center space-y-12">
                    <button-custom router-link='/presentation' class="w-2/5" strong uppercase shape="round" size="large" color="tertiary">Je découvre</button-custom>
                    <button-custom router-link='/registration' class="w-2/5" strong uppercase shape="round" size="large" color="tertiary">Je m'inscris</button-custom>
                    <button-custom router-link="/login" class="w-2/5" strong uppercase shape="round" size="large" color="tertiary">Je me connecte</button-custom>
                </div>
            </div>
        </ion-content>
        <ion-footer>
            <div class="container py-8 ion-text-end">
                <a @click="$router.push({name:'charte'})" class="text-sm">La promesse Happy Madeleine : Nous ne communiquerons jamais votre e-mail - Voir notre charte </a>
            </div>
        </ion-footer>
    </ion-page>
</template>

<script>
import { IonContent, IonPage, IonFooter } from '@ionic/vue';
import { defineComponent } from 'vue';
import NavBar from "@/components/Navbar";
import BubbleText from "@/components/BubbleText";
import ButtonCustom from "@/components/ButtonCustom";
import { useRouter } from 'vue-router';

export default defineComponent({
    name: 'HomePage',
    components: {
        NavBar,
        IonContent,
        IonPage,
        IonFooter,
        BubbleText,
        ButtonCustom
    },
    setup() {
        const route = useRouter();

        return {
            route,
        }
    }

});
</script>

<style scoped>

</style>
