<template>

    <div class="summary-component">

        <div class="summary-container bg-white rounded-xl p-6 shadow-xl mb-28">
            <div
                class="text-xl font-bold shadow-xl rounded-xl bg-primary text-white text-center min-w-[300px] mb-6 py-2 px-6 mx-auto">
                {{ activity.data.title }}
            </div>

            <div class="grid grid-cols-2 space-x-6">
                <div>
                    <a v-if="activity.data.photo" class="photo-card no-underline import" target="_blank"
                       :href="getDocumentUrl(activity.data.photo)">
                        <div class="photo" :style="getPhotoBackground"></div>
                    </a>

                    <div v-if="data['with-who']" class="bg-white shadow-xl rounded-xl p-3 relative">
                        <ion-img src="assets/icon/bloc_who.png" class="absolute -top-2 -left-5 h-14"></ion-img>
                        <p class="text-center block mb-2 mt-0 pl-3">{{ getActivityField('with-who').data.label }}</p>
                        <div class="bg-primary font-bold rounded-full text-white text-center py-1 px-8">{{
                                data['with-who']
                                                                                              }}
                        </div>
                    </div>

                    <div v-if="data['secret']" class="bg-primary shadow-xl rounded-xl p-3 relative mt-10">
                        <p class="text-center block mb-2 mt-0 text-white">{{
                                getActivityField('secret').data.label
                                                                          }}</p>
                        <div class="rounded-full font-bold bg-gray-50 text-center py-1 px-8 text-primary">
                            {{ data['secret'] }}
                        </div>
                        <ion-img src="assets/icon/stars1.png" class="absolute -top-5 -left-3 h-14"></ion-img>
                        <ion-img src="assets/icon/stars2.png" class="absolute -top-5 -right-4 h-14"></ion-img>
                    </div>

                    <div v-if="data['why']" class="bg-white shadow-xl rounded-xl p-3 relative mt-10">
                        <p class="text-center block mb-2 mt-0 px-6 ">{{ getActivityField('why').data.label }}</p>
                        <div class="rounded-full bg-gray-200 py-2 px-8">
                            {{ data['why'] }}
                        </div>
                        <ion-img src="assets/icon/bloc_heart.png" class="absolute -top-6 -right-4 h-14"></ion-img>
                    </div>
                </div>

                <div class="flex flex-col">
                    <p class="bg-primary text-white text-lg rounded-xl py-1 px-6 w-full text-center">
                        {{ getActivityField('description').data.label }}
                    </p>
                    <div class="bg-gray-200 rounded-xl py-3 px-4 whitespace-pre-line relative mt-10 h-full">
                        <div class="absolute -top-6 -left-2 px-6 py-1 bg-tertiary text-primary font-bold rounded-lg">
                            {{ decadeYear($store.getters.getHouseUser?.birthdate, activity.decade, activity.decade_gap) }}
                        </div>
                        {{ data['description'] }}
                    </div>
                </div>
            </div>
        </div>

        <div v-if="mode === 'summary' || mode === 'complete'"
             class="bg-secondary text-white text-center px-12 pt-6 pb-24 relative">
            <ion-img src="assets/persona/boy2.png" class="absolute h-20 -top-14 left-[15%] -rotate-12"></ion-img>
            <template v-if="mode === 'summary'">Si le résumé te convient, clique sur “oui”, sinon clique sur “non” pour
                                                retourner en arrière.
            </template>
            <template v-else-if="mode === 'complete'">Pour passer à une autre activité, appuie sur “Terminer
                                                      l'activité”
            </template>
        </div>

        <div class="buttons">
            <template v-if="mode === 'summary'">
                <button-custom strong border border-color="primary" shape="round" color="tertiary" size="md"
                               @click="onRetry">
                    Non
                </button-custom>
                <button-custom strong border shape="round" color="primary" size="md" @click="onComplete">
                    Oui !
                </button-custom>
            </template>
            <template v-else-if="mode === 'retry'">
                <button-custom strong shape="round" color="tertiary" size="md" @click="goto(1, 'modify')">
                    Recommencer
                </button-custom>
                <button-custom strong shape="round" color="primary" size="md" @click="onComplete">
                    J'ai terminé
                </button-custom>
            </template>
            <template v-else-if="mode === 'complete'">
                <button-custom strong shape="round" border color="secondary" size="md" @click="createActivity">
                    <ion-icon :icon="addIcon"></ion-icon>
                    {{ activity.name_add }}
                </button-custom>
                <button-custom strong shape="round" border color="primary" size="md" @click="closeActivity">Terminer
                                                                                                            l'activité
                </button-custom>
            </template>
            <template v-else-if="mode === 'view'">
                <button-custom strong shape="round" border color="tertiary" size="md" @click="onModify">Je veux
                                                                                                        modifier
                </button-custom>
                <button-custom v-if="!activity.shared" border strong shape="round" color="primary" size="md"
                               @click="onShare">
                    Je partage
                </button-custom>
                <button-custom strong shape="round" border color="secondary" size="md" @click="createActivity">
                    <ion-icon :icon="addIcon"></ion-icon>
                    {{ activity.name_add }}
                </button-custom>
            </template>
            <template v-else-if="mode === 'read'">
                <button-custom strong shape="round" border color="primary" size="md"
                               @click="onOpenModalFeed(activity.post.id)"
                               class="my-0">
                    Réagir dans MA FAMILLE
                </button-custom>
                <button-like from-type="post" :from-id="activity.post.id"
                             :liked="activity.post.liked"
                             @on-liked="onLiked"
                             show-label></button-like>
            </template>
        </div>
    </div>

</template>
<script>
import { IonIcon, IonImg } from '@ionic/vue';
import ButtonCustom from "@/components/ButtonCustom";
import {
    addOutline as addIcon,
} from 'ionicons/icons';
import { mapGetters } from "vuex";
import { alertBox } from "@/services/AlertService";
import openToast from "@/services/ToastService";
import { decadeYear, showFirstError } from "@/services/FormatService";
import { openModalFeedWall } from "@/services/ModalService";
import { mediaUrl } from "@/services/RoutingService";
import ButtonLike from "@/components/ButtonLike";

export default {
    name: "SummaryComponent",
    components: {
        IonImg,
        ButtonCustom,
        IonIcon,
        ButtonLike
    },
    props: {
        activity: Object,
        data: Object,
        defaultMode: String,
    },
    data() {
        return {
            addIcon,
        }
    },
    mounted() {
    },
    computed: {
        ...mapGetters([
            'getActivity',
        ]),
        mode: function () {
            if (this.defaultMode) {
                return this.defaultMode;
            }
            else if (this.$route.query?.mode) {
                if (this.$store.getters.isHouseOwner) {
                    return this.$route.query.mode;
                }
                else {
                    return 'read';
                }
            }
            return 'summary';
        },

        getPhotoBackground: function () {
            if (this.activity.data.photo) {
                return { 'backgroundImage': 'url(' + this.getDocumentUrl(this.activity.data.photo) + ')' };
            }
            return null;
        },
    },
    methods: {
        decadeYear,
        closeActivity: function () {
            this.$router.push('/house/' + this.$route.params.house + '/' + this.$route.params.room + '?completed=1');
        },
        onRetry: function () {
            window.location.reload();
        },

        onComplete: async function () {
            const alert = await alertBox("Tu as terminé l’activité " + this.activity.name + " <br> Souhaites-tu la partager avec tes proches ?",
                null, 'white',
                [
                    {
                        text: 'Je ne partage pas',
                        role: 'cancel',
                    },
                    {
                        text: 'Je partage!',
                        role: 'confirm',
                    }]
            );

            this.axios.post('house/' + this.$route.params.house + '/activity/' + this.$route.params.activity + '/completed')
                .then(() => {
                    this.$store.commit('setActivity', { completed: true });
                })
                .catch((error) => {
                    openToast(showFirstError(error?.response?.data?.errors))
                });

            if (alert.role === 'cancel') {
                this.onDontShare();
            }
            else if (alert.role === 'confirm') {
                this.onShare();
            }

        },

        onModify: function () {
            this.$emit('changeStep', 0);
            this.$emit('changeMode', 'modify');
        },

        onShare: async function () {
            this.axios.post('house/' + this.$route.params.house + '/activity/' + this.$route.params.activity + '/shared', { noLoader: true })
                .then(() => {
                    this.$store.commit('setActivity', { shared: true });
                })
                .catch((error) => {
                    openToast(showFirstError(error?.response?.data?.errors))
                });

            const alert = await alertBox("Tu as partagé l’activité " + this.activity.name + " avec tes proches ! <br><br>Une notification a été postée sur le groupe de ta famille",
                null, 'white',
                [
                    {
                        text: 'Super !',
                        role: 'cancel',
                        cssClass: 'primary'
                    },
                    {
                        text: 'Aller sur le groupe',
                        role: 'confirm',
                        cssClass: 'outline-primary'
                    }]
            );

            if (alert.role === 'cancel') {
                this.$emit('changeMode', 'complete');
                this.$emit('changeStep', 0);
            }
            else if (alert.role === 'confirm') {
                this.$emit('changeMode', 'complete');
                this.$emit('changeStep', 0);
                this.onOpenModalFeed();
            }
        },
        onDontShare: async function () {
            const alert = await alertBox("Tu as choisi de ne pas partager l’activité " + this.activity.name + "<br><br>Tu pourras toujours la partager plus tard en appuyant sur “partager”.",
                null, 'white',
                [
                    {
                        text: 'Super !',
                        role: 'cancel',
                        cssClass: 'primary'
                    }]
            );

            if (alert.role === 'cancel') {
                this.$emit('changeMode', 'complete');
                this.$emit('changeStep', 0);
            }
        },

        onLiked: function () {
            let activity = this.activity;
            activity.post.liked = !this.activity.post.liked;
            this.$store.commit('setActivity', activity);
        },

        onOpenModalFeed: function (postId = null) {
            openModalFeedWall(postId);
        },

        goto: function (mode = null) {

            this.$router.push({
                name: 'activity',
                params: {
                    house: this.$route.params.house,
                    room: this.$route.params.room,
                    activity: this.$route.params.activity,
                    mode: mode
                }
            });

        },

        createActivity: function () {
            this.axios.post('/house/' + this.$route.params.house + '/room/' + this.$route.params.room + '/activity', {
                activity: this.activity.default_activity_id,
            })
                .then((response) => {
                    const activity = response.data;
                    this.$router.push({
                        name: 'activity',
                        params: {
                            house: this.$route.params.house,
                            room: this.$route.params.room,
                            activity: activity.id
                        }
                    });
                })
                .catch((error) => {
                    openToast(showFirstError(error?.response?.data?.errors))
                })
        },

        getDocumentUrl: function (url) {
            if (url) {
                return mediaUrl('activities/' + url);
            }
        },

        getActivityField: function (type) {
            for (const field of this.activity.fields) {
                if (field.type === type) {
                    return field;
                }
            }
            return null;
        },
    }
}
</script>

<style lang="scss" scoped>

.summary-component {

    @apply flex-1 w-full self-start;

    .summary-container {
        max-width: 80%;
        @apply mx-auto flex flex-col ;
    }

    .photo-card {
        z-index: 10;
        @apply relative flex-none ;
    }

    .buttons {

        @apply flex justify-center text-center space-x-24 fixed bottom-4 left-0 right-0 z-10;
    }
}

</style>
