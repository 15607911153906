import { API_URL } from "@/constants/UrlConstant";
import router from "@/router";
import store from "@/store";

export function goToUserHouse(router, user, houseId = null) {
    if (user.is_verified) {
        if (localStorage.getItem('invitation')) {
            localStorage.removeItem('invitation');
            router.push({ name: 'invitations' });
        }
        else {
            if (houseId) houseId = parseInt(houseId);
            const myHousesIds = user.my_houses.map(value => value.id);
            if (houseId !== null && houseId > 0 && myHousesIds.includes(houseId)) {
                router.push({ name: 'house', params: { house: houseId } });
            }
            else if (myHousesIds.length === 1) {
                router.push({ name: 'house', params: { house: myHousesIds[0] } });
            }
            else if (myHousesIds.length > 1) {
                router.push({ name: 'login_choice_house' });
            }
            else {
                router.push({ name: 'login_first' });
            }
        }
    }
    else {
        router.push({ name: 'registration_confirm' });
    }
}

export function goToUserActivity(house, room, activity) {
    let goList = false;
    if (activity.activities?.length === 1 && activity.activities[0].completed) {
        goList = true;
    }

    if (activity.activities?.length > 1 || !store.getters.isHouseOwner || goList) {
        router.push({
            name: 'activities_list',
            params: {
                house: house,
                room: room,
                activity: activity.id,
            }
        });
    }
    else {
        router.push({
            name: 'activity',
            params: {
                house: house,
                room: room,
                activity: activity.activities[0].id,
            }
        });
    }
}

export function mediaUrl(url) {
    if (url) {
        return API_URL+'media/'+url+'?token='+localStorage.getItem('access_token');
    }
}
