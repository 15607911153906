<template>
    <ion-header class="ion-no-border px-6" :style="headerCss">
        <ion-toolbar class="" :style="toolbarCss">
            <ion-buttons slot="start" class="flex-1">
                <button-icon v-if="showBackButton" @click="backButtonHandler" icon-url="assets/images/arrow_left.png"
                             icon-height="35px"></button-icon>
                <button-icon v-if="showCustomButton" @click="$router.push({name: 'book_custom'})"
                             icon-url="assets/icon/book_customise.svg"
                             icon-height="50px">
                    Personnaliser
                </button-icon>
                <button-icon v-if="showOrderButton" @click="goBookOrder" icon-url="assets/icon/book_order.png"
                             icon-height="50px">
                    Commander
                </button-icon>
            </ion-buttons>
            <div class="bg-tertiary text-primary w-fit mx-auto text-center px-8 py-4 font-bold text-lg rounded-2xl">
                {{ title }}
            </div>
            <ion-buttons slot="end" v-show="showButtons" class="flex-1 ion-justify-content-end">
                <button-icon v-if="showOrdersButton" @click="$router.push({name: 'book_orders'})"
                             icon-url="assets/icon/book.png"
                             icon-height="50px" class="!w-32">
                    Mes commandes
                </button-icon>
                <button-icon @click="goHome" icon-url="assets/icon/close.svg"></button-icon>
            </ion-buttons>
        </ion-toolbar>
    </ion-header>
</template>

<script>
import { IonHeader, IonToolbar, IonButtons, modalController } from '@ionic/vue';
import { defineComponent } from 'vue';
import ButtonIcon from "@/components/ButtonIcon";
import { caretBack } from "ionicons/icons";
import { goToUserHouse } from "@/services/RoutingService";
import ModalSearch from "@/components/Modal/ModalSearch.vue";
import { alertBox } from "@/services/AlertService";
import { PAGES_MAX, PAGES_MIN } from "@/constants/BookConstant";

export default defineComponent({
    name: "NavBarBook",
    components: {
        ButtonIcon,
        IonHeader, IonToolbar, IonButtons,
    },
    props: {
        backButtonCallback: Function,
        showBackButton: {
            type: Boolean,
            default: true,
        },
        showCustomButton: {
            type: Boolean,
            default: true,
        },
        showOrderButton: {
            type: Boolean,
            default: true,
        },
        showOrdersButton: {
            type: Boolean,
            default: false,
        },

        showSearchbar: {
            type: Boolean,
            default: false,
        },
        showLogo: {
            type: Boolean,
            default: true,
        },
        showButtons: {
            type: Boolean,
            default: true,
        },
        backgroundColor: {
            type: String,
            default: 'white' // white||transparent
        },
        title: String,
        book: Object,
        nbPages: Number,
    },
    data() {
        return {
            caretBack,
            searchModal: null,
        }
    },
    computed: {
        headerCss() {
            let css = {};
            if (this.backgroundColor === 'white') {
                css.backgroundColor = '#fff';

            }
            else if (this.backgroundColor === 'transparent') {
                css.backgroundColor = 'transparent';
                css.position = 'absolute';
                css.top = '0';
            }
            return css;
        },
        toolbarCss() {
            return {
                '--background': this.backgroundColor === 'white' ? '#fff' : 'transparent',
            }
        }
    },
    methods: {
        goAccount: function () {
            if (this.$route.params.house) {
                this.$router.push('/house/' + this.$route.params.house + '/account')
            }
            else {
                this.$router.push('/account');

            }
        },
        goHome: function () {
            goToUserHouse(this.$router, this.$store.getters.getUser, this.$route.params.house);
        },

        async goBookOrder() {

            if (this.book.parameters?.length <= 0) {
                const alert = await alertBox(
                    "Pour commander votre livre, vous devez d'abord le personnaliser afin d'y inclure un nombre de page entre " + PAGES_MIN + " et " + PAGES_MAX + ".",
                    null,
                    null,
                    [
                        {
                            'text': 'Annuler',
                            'role': 'cancel',
                        },
                        {
                            'text': 'Personnaliser mon livre',
                            'role': 'confirm',
                        }
                    ])
                if (alert.role === 'confirm') {
                    this.$router.push({ name: 'book_custom' })
                }
                return;
            }
            if (this.nbPages < PAGES_MIN) {
                const alert = await alertBox(
                    "Votre livre doit contenir plus de " + PAGES_MIN + " pages. Vous en avez " + this.nbPages + ".<br>Vous pouvez modifier la personnalisation de votre livre pour inclure d'autres activités.",
                    null,
                    null,
                    [
                        {
                            'text': 'Annuler',
                            'role': 'cancel',
                        },
                        {
                            'text': 'Modifier mon livre',
                            'role': 'confirm',
                        }
                    ])
                if (alert.role === 'confirm') {
                    this.$router.push({ name: 'book_custom' })
                }
                return;
            }

            this.$router.push({ name: 'book_custom', query: { mode: 'summary' } });
        },

        async onClickSearch() {
            if (!this.searchModal) {
                await this.openSearchModal();
            }
            else {
                this.closeSearchModal();
            }
        },

        async openSearchModal() {
            this.searchModal = await modalController.create({
                component: ModalSearch,
                componentProps: {
                    searchQuery: this.searchQuery
                },
                breakpoints: [0, 0.5, 1],
                initialBreakpoint: 1,
            });

            await this.searchModal.present();

            await this.searchModal.onDidDismiss();
            this.searchModal = null;
        },

        closeSearchModal() {
            if (this.searchModal) {
                this.searchModal.dismiss();
                this.searchModal = null;
            }
        },

        backButtonHandler() {
            if (this.backButtonCallback) {
                this.backButtonCallback();
            }
            else {
                this.$router.back();
            }
        }
    }
})
</script>

<style lang="scss">
ion-toolbar {
    --background: transparent;

    .logo {
        height: 80px;
    }

    ion-buttons {
        min-width: 90px;
    }

    .buttons-last-slot {
        ion-img {
            height: 100%;
        }
    }

    ion-back-button {
        --background: var(--ion-color-primary);
        --border-radius: 22px;
        --color: #fff;
        --padding-end: 22px;
        --padding-start: 12px;
        --icon-font-size: 22px;
        --icon-margin-end: 6px;
        font-weight: bold;
    }

    ion-searchbar.main-search {
        padding-top: 6px;
        --background: #fff;
        --border-radius: 30px;
        --box-shadow: 0px 0px 0px 3px var(--ion-color-primary);
        --icon-color: var(--ion-color-primary);

        .searchbar-input {
            padding-inline-start: 40px !important;
            @apply text-sm;
        }

        .searchbar-search-icon {
            left: 12px;
        }

    }
}

</style>
